import { replaceLessonCode } from 'shared-values';

import { UserReviewType } from '@templates/Home/components/HomeAdvertisement/HomeAdvertisement';

export interface LessonProductType {
  totalPrice: number;
  discountPrice?: number;
  additionalPrice?: number;
  code: LessonCode;
  name: string;
}

interface AdAcademyInfoType {
  currentContractId: string;
  academyName: string;
  academyId: string;
  regionText: string;
  reviewScore: number;
  reviewCount: number;
  lessonPriceList: {
    price: number;
    discountPrice?: number;
    additionalPrice?: number;
    code: LessonCode;
    name: string;
  }[];
  image: string;
  customKeyword: string;
  tags: (string | null)[];
  reviews: {
    id: string;
    userName: string;
    images: string[];
    average: number;
    reviewText: string;
    facility: number;
    lecture: number;
    service: number;
    lessonCode: LessonCode;
    lectureProgress: string;
  }[];
}

interface UserLocationType {
  bubCode: string;
  dongName: string;
  siGunGuName: string;
}

export class AdvertisementDataModel {
  location: {
    bubCode: string;
    dongName: string;
    siGunGuName: string;
  };
  academy: {
    academyId: string;
    academyName: string;
    academyLabel: string;

    tags: {
      advantages: (string | null)[];
      mainTag: string;
    };
    location: string;
    products: LessonProductType[];
    reviews: {
      totalRating: number;
      totalParticipants: number;
      userReviews: UserReviewType[];
    };
    image: string;
  };
  contractId: string;

  constructor({
    adAcademy,
    userLocation,
  }: {
    adAcademy: AdAcademyInfoType;
    userLocation: UserLocationType;
  }) {
    (this.location = {
      bubCode: userLocation.bubCode,
      dongName: userLocation.dongName,
      siGunGuName: userLocation.siGunGuName,
    }),
      (this.academy = {
        academyLabel: adAcademy.academyName,
        academyId: adAcademy.academyId,
        academyName: adAcademy.academyName.split(' ')[0],
        tags: {
          advantages: adAcademy?.tags,
          mainTag: adAcademy.customKeyword,
        },
        location: adAcademy.regionText.split(' ').slice(0, 3).join(' '),
        products: adAcademy.lessonPriceList.map((lesson) => {
          return {
            totalPrice: Number(lesson.price || 0),
            code: replaceLessonCode(lesson.code),
            name: lesson.name,
          };
        }),
        reviews: {
          totalRating: adAcademy.reviewScore,
          totalParticipants: adAcademy.reviewCount,
          userReviews: adAcademy.reviews.map((review) => ({
            reviewId: review.id,
            lessonCode: replaceLessonCode(review.lessonCode),
            lectureProgress: review.lectureProgress,
            userName: review.userName,
            averageRate: review.average,
            detailedScores: {
              facility: review.facility,
              lecture: review.lecture,
              service: review.service,
            },
            contents: {
              images: review.images,
              description: review.reviewText,
            },
          })),
        },
        image: adAcademy.image,
      });
    this.contractId = adAcademy.currentContractId;
  }

  getAcademy() {
    return this.academy;
  }
}
