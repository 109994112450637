import { useEffect, useRef, useState } from 'react';

import { NewIcon } from 'design-system';

const AdvertisementInfoTooltipPolygons = () => {
  return (
    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Polygon 1"
        d="M4.58579 1.41421C5.36683 0.633165 6.63317 0.633165 7.41421 1.41421L12 6L-7.16729e-08 6L4.58579 1.41421Z"
        fill="#242523"
      />
    </svg>
  );
};

interface Props {
  clickable?: boolean;
}

const AdvertisementInfoTooltip = ({ clickable = false }: Props) => {
  const [tooltipOpen, setToolTipOpen] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // 모달 외부 클릭 감지
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setToolTipOpen(false); // 외부 클릭 시 툴팁 닫기
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipRef]);

  return (
    <div className="relative" ref={tooltipRef}>
      <button
        className="text-new-gray-400 border-1 border-new-gray-200 flex cursor-default items-center gap-2 rounded-full px-6 py-2 text-center"
        onClick={() => {
          clickable && setToolTipOpen((prev) => !prev);
        }}
      >
        <span className="text-new-Caption2-medium text-center">AD</span>
      </button>
      {tooltipOpen && (
        <div className=" text-new-white bg-new-gray-900 absolute bottom-[-40px] right-[-8px] flex w-fit items-center justify-start gap-4 whitespace-nowrap rounded-[8px] px-12  py-8">
          <span className="text-new-Caption1-bold">
            {'운전선생 광고상품을 구매한 제휴 업체입니다.'}
          </span>
          <button onClick={() => setToolTipOpen(false)}>
            <NewIcon icon="x-outlined" size={16} className="fill-new-white"></NewIcon>
          </button>
          <div className="absolute right-12 top-[-6px]">
            <AdvertisementInfoTooltipPolygons></AdvertisementInfoTooltipPolygons>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvertisementInfoTooltip;
