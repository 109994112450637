import { AdvertisementDataModel } from '@models/advertisement-data';

import trimEmoji from './use-trim-emoji';

export const createAdLogLabel = (data: {
  adData: AdvertisementDataModel;
  userId: string;
  clickArea?: string;
  clickReviewIndex?: number;
  flag?: 'control' | 'test';
}) => {
  const { adData, userId, clickArea, clickReviewIndex, flag } = data;
  return {
    academy_id: adData.academy.academyId,
    academy_label: adData.academy.academyName,
    user_address: adData.location.siGunGuName,
    contract_id: adData.contractId,
    device_id: window.deviceToken || null,
    user_id: userId || null,
    academy_location: adData.academy.location.split(' ')[1],
    academy_mainTag: adData.academy.tags.mainTag,
    academy_advantages: adData.academy.tags.advantages
      .map((advantage) => (advantage ? trimEmoji(advantage) : ''))
      .join(','),
    click_area: clickArea || null,
    click_review_index: clickReviewIndex || null,
    flag: flag || null,
  };
};
