import { useGetLocation } from '@hooks/use-get-location/use-get-location';
import { getSavedFunnelLocation, getSavedLatestLocation } from '@utils/location';

const useGetAdvertisementLocation = () => {
  const { locationPermission, myLocation } = useGetLocation({ isSilent: true });
  const userMapLocation = getSavedLatestLocation();
  const funnelLocation = getSavedFunnelLocation();

  if (funnelLocation) {
    return {
      lat: funnelLocation.latitude,
      lng: funnelLocation.longitude,
    };
  }

  if (locationPermission !== 'denied' && myLocation) {
    return {
      lat: myLocation.latitude,
      lng: myLocation.longitude,
    };
  }

  if (userMapLocation) {
    return {
      lat: userMapLocation.latitude,
      lng: userMapLocation.longitude,
    };
  }

  return null;
};

export default useGetAdvertisementLocation;
